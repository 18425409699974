<template lang="pug">
.template-chooser-recommendations-skeleton
  .row.mb-2(v-for="index in rows")
    .col(v-for="index in cols")
      .skeleton-container
        vue-skeleton-loader.mb-3(type="rect" width="100%" height="100%" rounded)
</template>

<script>
  export default {
    props: {
      rows: { type: Number, default: 2 },
      cols: { type: Number, default: 2 },
    },
  };
</script>
<style lang="sass">
  .skeleton-container
    width: 100%
    aspect-ratio: 157 / 89
</style>
